<template>
  <div id="home">
    <div v-if="errorMessage" class="error-banner">
      <h1 class="error">{{errorMessage}}</h1>
    </div>    
    <div class="component card" id="payment" v-if="!success">
      <div class="title">
        <h1>Add your new card</h1>
      </div>
      <form @submit.prevent="submitCard" id="payment-form">
        <label>Your full name</label>
        <input id="card-holder-name" type="text" v-model="name">

        <div v-if="paymentRequired">
          <label>Card</label>
          <div id="card-element"><!--Stripe.js injects the Card Element--></div>
        </div>
        <p id="card-error" role="alert">{{ paymentStatusError }}</p>
        <div v-if="submittingCard" class="loader"></div>
        <button :disabled=submittingCard v-else>Add your new card</button>
      </form>
    </div>
    <div class="component card" v-else>
      <div class="success" v-if="success">
        <h1>Success!</h1>
        <p>Your new card ending in {{cardLast4}} was successfully added.</p>
        <router-link to="/dashboard" class="small-button">Back to the dashboard</router-link>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';



export default {
  name: 'AddCard',
  props: ["subscriptionTier", "clientSecret", "amount", "total", "customerId", "alert", "upgradeInfo", "subscriptionCoupon"],
  data: () => ({
    stripePublicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
    stripe: null,
    elements: null,
    card: null,
    newPaymentMethod: null,
    name: '',
    submittingCard: false,
    success: false,
    paymentStatusError: '',
    invoiceNeedingPayment: null,
    errorMessage: null,
  }),
  mounted() {
    this.scrollToElement();
    this.configureStripe();
  },
  methods: {
    configureStripe() {
      this.stripe = Stripe(this.stripePublicKey);
      this.elements = this.stripe.elements();

      var style = {
        base: {
          color: "#32325d",
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#32325d"
          }
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };

      this.card = this.elements.create('card', {style: style});
      this.card.mount('#card-element');
    },
    submitCard() {
      if (!this.stripe) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      this.submittingCard = true;
      console.log("Email:", this.userEmail);


      // create the dang payment method
      this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: this.name,
            email: this.userEmail,
          }
        }).then((result) => {
          if (result.error) {
            // errors!
            if (result.error.type === "card_error") {
              this.paymentStatusError = result.error.message;
            } else {
              console.log(result.error);
              this.paymentStatusError = result.error.message;
            }
            this.submittingCard = false;
          } else {
            // payment method successfully created
            this.newPaymentMethod = result.paymentMethod;
            
            // now pay

            var data = {
              customerId: this.customerId,
              paymentMethodId: this.newPaymentMethod.id,
              email: this.userEmail,
            };
            
            
            // post it
            return this.$store.dispatch('addPaymentMethod', data)
            .then((response) => {
              this.submittingCard = false;

              if (result.error) {
                // The card had an error when trying to attach it to a customer.
                throw result;
              }

              return result;

            })
          }
        }).then(this.onCardUpdateComplete)
        .catch((error) => {
          console.log(error);
          if (error.response) {
            this.paymentStatusError = error.response.data.error;
            this.submittingCard = false;
          } else {
            this.paymentStatusError = "Hm, looks like there's been an error. We're looking into it."
            this.submittingCard = false;
          }
        });;
    },
    onCardUpdateComplete(result) {
      this.success = true;
    }
  },
  computed: {
    paymentRequired: function() {
      if (this.upgradeInfo) { // we're checking out an inline upgrade of the subscription
        if (this.upgradeInfo.next_tier.total === 0) {
          return false;
        } else {
          return true;
        }
      } else if (this.subscriptionTier != null) { // we're checking out a new subscription
      if (this.subscriptionTier.total === 0) {
        return false;
      } else {
        return true;
      }
      } else if (this.amount != null) { // really just for the BTB alerts
        return true;
      } else {
        return true;
      }
    },
    userEmail: function() {
      return this.$store.state.email;
    },
    cardName: function() {
      if (this.$store.state.stripeAccount.payment_method) {
        return this.$store.state.stripeAccount.payment_method.safe_payment_info.brand;
      } else if (this.newPaymentMethod) {
        return this.newPaymentMethod.card.brand;
      } else {
        return null;
      }
    },
    cardLast4: function() {
      if (this.$store.state.stripeAccount.payment_method) {
        return this.$store.state.stripeAccount.payment_method.safe_payment_info.last4;
      } else if (this.newPaymentMethod) {
        return this.newPaymentMethod.card.last4;
      } else {
        return null;
      }
    },
    subscription () {
      return this.$store.state.stripeAccount.subscription;
    },
  },
}
</script>

<style scoped lang="scss">
#home {
  margin-top: 125px;
}
#payment {
  .title {
    .pricing-description {
      font-size: 1.2em;
      em {
        color: $payment-button;
        background-color:white;
        text-decoration: none;
      }
    }
  }
  p {
    margin-bottom: 1em;
    text-align: center;
    // color: white;
  }
  #payment-form {
    width: 30vw;
    min-width: 500px;
    align-self: center;

    padding: 15px 40px;
    margin: auto;
    background: white;
  }

  label {
    padding:10px;
    display: block;
  }

  input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid black;
    height: 44px;
    font-size: 16px;
    width: calc(100% - 24px);
    background: white;
    text-align: center;
  }
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  .hidden {
    display: none;
  }
  #card-error {
    color: $red;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    margin-top: 12px;
  }
  #card-element {
    border-radius: 6px;
    padding: 22px 12px;
    border: 1px solid black;
    width: calc(100% - 24px);
    background: white;
    position: relative;

    div {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  #payment-request-button {
    margin-bottom: 8px;
  }
  /* Buttons and links */
  button {
    background: $payment-button;
    color: #ffffff;
    font-family: Arial, sans-serif;
    border-radius: 6px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  button:hover {
    filter: contrast(115%);
  }
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: $payment-button;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: $payment-button;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
    #payment-form {
      // width: 200px;
      min-width: 200px;
      width: auto !important;
      border-radius: 0 0 8px 8px;
    }
  }
}
.success {
  padding: 1em 0;
  .small-button {
    margin: 2em auto;
    display: inline-block;
  }

}
</style>
